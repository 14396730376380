import React, { FC } from "react"
import { Stack, Text, Box, Center } from "../common"
import { Layout } from "../components/Layout"
import { ZHelmet } from "../components/ZHelmet"

const PrivacyPolicy: FC = props => {
  return (
    <Layout>
      <ZHelmet
        title="Privacy policy | best NBFC | Personal Loan | India"
        description="Get complete detail about privacy policy of Zavron Finserv best NBFC or persoanl provider in India just visit page to know more about the Zavron Finserv"
      />
      <Box>
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s2)">
            <Text style={{ textAlign: "center" }} variant="h3">
              Privacy Policy
            </Text>
            <Stack space="var(--s0)">
              <Text variant="p">
                Zavron Finance Private Limited is a Company incorporated under
                Companies Act 2013 and registered with RBI as NBFC. The
                registered address of the Company is 125, Zavron Finance Private
                Limited, Maharaj baugh Road, Sitabuldi, Nagpur -440013.
              </Text>
              <Text variant="p">
                For the purpose of this Privacy Policy, wherever the context so
                requires the term "You" shall mean any natural or legal person
                including Online and offline clients and the term “We” or
                “Company” shall mean Zavron Finance Private Limited.
              </Text>
              <Text variant="p">
                We understand that your Personal Information is very important
                and We acknowledge that the personal details that you impart to
                us, is to be kept in strict confidentiality and to use the
                information only in the manner which would be beneficial to our
                customers. Your Information including any sensitive
                financialinformation collected (as defined under the Information
                Technology Act, 2000), if any, on computers that may be
                protected by physical as well as reasonable technological
                security measures and procedures in accordance with Information
                Technology Act 2000 and Rules thereunder.
              </Text>
              <Text variant="p">
                The Privacy Policy shall cover the personal information of the
                individual or any other information collected from the visitor’s
                browser as provided to Zavron Finance Private Limited
                (hereinafter referred as the ‘Company’).
              </Text>
              <Text variant="p" style={{ textDecoration: "underline" }}>
                This Policy shall cover the information as mentioned below:
              </Text>
              <Text variant="p">Personal Information/Data</Text>
              <Text variant="p">Sensitive personal Information/Data</Text>
              <Text variant="p">
                The Information shall not be shared with any external
                organization unless the same is necessary to protect the
                interests of the Company or to enable the Company to provide you
                services or to enable the completion/compilation of a
                transaction, credit reporting or pursuant to the terms and
                conditions applicable to such Information as agreed to with the
                Company or pursuant to any requirement of law/regulations or any
                Government/court/other relevant authority’s directions/orders.
              </Text>
              <Text variant="p">
                All Information collected shall be used for the relevant lawful
                purposes connected with various functions or activities related
                to services in which the Concerned Person is interested, and/or
                to help determine the eligibility of the Concerned Persons for
                the product/services requested/ applied/ shown interest in.
              </Text>
              <Text variant="p">
                We may also share your Information, without obtaining your prior
                written consent, with government agencies mandated under the law
                to obtain information for the purpose of verification of
                identity, or for prevention, detection, investigation including
                cyber incidents, prosecution, and punishment of offences, or
                where disclosure is necessary for compliance of a legal
                obligation. Any Information may be required to be disclosed to
                any third party by us by an order under the law for the time
                being in force.
              </Text>
              <Text variant="p">
                As regards the information collected from visitors of the
                website online (“visitor”), the Company will use the Information
                to improve the Covered Persons experience on the site and make
                subsequent offers to the visitor on products which may be of
                interest to him / her, if so agreed while giving information.
              </Text>
              <Text variant="p">
                The Company’s website uses Google Analytics, a web analytics
                service provided by Google, Inc. (“Google”). Google Analytics
                uses cookies that are text files containing small amounts of
                information which are downloaded to your device when you visit a
                website in order to provide a personalised browsing experience.
              </Text>
              <Text variant="p">
                These cookies collect information analytics about how users use
                a website, for instance often visited pages. All information
                collected by third party cookies is aggregated and therefore
                anonymous. By using our website user/s agree that these types of
                cookies can be placed on his/her device. User/s is free to
                disable/delete these cookies by changing his/her web browser
                settings. The Company is not responsible for cookies placed in
                the device of user/s by any other website and information
                collected thereto.
              </Text>
              <Text variant="p">Disclaimer:</Text>
              <Text variant="p">
                Please note that this policy may change from time to time.
                Please visit our website to keep yourself abreast of any changes
                to this policy.
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Box>
    </Layout>
  )
}

export default PrivacyPolicy
